import axiosInstance from "../../auth/axiosInstance";
import React, { useEffect, useState } from "react";
import { AuthData } from "../../auth/AuthWrapper";
import Card from "../structure/card";

const MesCours = () => {
  const [cours, setCours] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = AuthData();

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/mescours?email=${user.name}`)
      .then(async (response) => {
        const courseIds = response.data;

        if (courseIds.length === 0) {
          setLoading(false);
          return;
        }

        const coursesDetailsPromises = courseIds.map(async (idCours) => {
          try {
            const courseResponse = await axiosInstance.get(
              `/cours/i?idCours=${idCours}`
            );
            const courseDetails = courseResponse.data;
            const checkValidateResponse = await axiosInstance.get(
              `/mescours/v?idCours=${idCours}&emailEleve=${user.name}`
            );
            const checkValidate = checkValidateResponse.data;

            if (checkValidate) {
              return courseDetails;
            } else {
              return null;
            }
          } catch (error) {
            console.error("Error ");
            return null;
          }
        });

        const coursesDetails = await Promise.all(coursesDetailsPromises);

        const filteredCoursDetails = coursesDetails.filter(
          (course) => course !== null
        );

        setLoading(false);
        setCours(filteredCoursDetails);
      })
      .catch((error) => {
        console.error("Error ");
        setLoading(false);
      });
  }, [user.name]);

  const choosePicture = (coursSubject, coursLevel) => {
    const specialLevels = ["T_C", "BAC_1", "BAC_2", "Universitaire"];

    switch (coursSubject) {
      case "maths":
        return specialLevels.includes(coursLevel)
          ? "/img/maths_ad.jpg"
          : "/img/maths.jpg";
      case "pc":
        return specialLevels.includes(coursLevel)
          ? "/img/pc_ad.jpg"
          : "/img/pc.jpg";
      case "svt":
        return specialLevels.includes(coursLevel)
          ? "/img/svt_ad.jpg"
          : "/img/svt.jpg";
      case "arabe":
        return "/img/arabe.jpg";
      case "anglais":
        return specialLevels.includes(coursLevel)
          ? "/img/eng_ad.jpg"
          : "/img/english.jpg";
      case "francais":
        return specialLevels.includes(coursLevel)
          ? "/img/fr_ad.jpg"
          : "/img/fr.jpg";
      case "espagnol":
        return "/img/espagnol.jpg";
      case "education_islamique":
        return "/img/E_I.jpg";
      case "philosophie":
        return "/img/philosophie.jpg";
      case "histoire":
        return specialLevels.includes(coursLevel)
          ? "/img/histoire_ad.jpg"
          : "/img/histoire.jpg";
      case "info":
        return "/img/info.jpg";
      case "electronique":
        return "/img/electronique.jpg";
      case "economie":
        return specialLevels.includes(coursLevel)
          ? "/img/eco_ad.jpg"
          : "/img/economie.jpg";
      case "autre":
        return "/img/autre.jpg";
      default:
        return "/img/autre.jpg";
    }
  };

  return (
    <div>
      <h1> Mes Cours Payés </h1>
      {loading ? (
        <div
          className="spinner-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      ) : (
        <div>
          {cours.length === 0 ? (
            <div className="not-found-message">
              <p>Aucun cours correspondant n'a été trouvé.</p>
            </div>
          ) : (
            <div className="container">
              <div className="row">
                {cours.map((cours) => (
                  <div className="col-md-4 col-sm-6 mb-3" key={cours.idCours}>
                    <Card
                      id={cours.idCours}
                      titre={cours.title}
                      description={cours.description}
                      prix={cours.price}
                      coursSubject={cours.coursSubject}
                      coursLevel={cours.coursLevel}
                      imageSource={choosePicture(cours.coursSubject)}
                      profName={
                        cours.profNom
                          ? `${cours.profNom} ${cours.profPrenom}`
                          : "Unknown Professor"
                      }
                      url="mescoursdetails"
                      isVerified={cours.profVerified}
                      rating={cours.rating}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MesCours;
