import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {
  Container,
  Grid,
  TextField,
  Button,
  Box,
  Snackbar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axiosInstance from "../../auth/axiosInstance";
import { AuthData } from "../../auth/AuthWrapper";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import WithdrawDialog from "../../auth/WithdrawDialog";
import sha256 from "crypto-js/sha256";

const AdrU = () => {
  const { user } = AuthData();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState(null);

  const emptyForm = {
    prenom: "",
    nom: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  };
  const [userInfo, setUserInfo] = useState(emptyForm);
  const { prenom, nom, email, phone, password, confirmPassword } = userInfo;

  const onInputChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  // function to fetch user info , how much courses he has
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const hashedPassword = sha256(password).toString();
      const dataToSend = {
        prenom,
        nom,
        email,
        phone,
        password: hashedPassword,
        confirmPassword,
      };
      const csrfToken = await fetchXsrfToken();
      const response = await axiosInstance.post(`/account/adr`, dataToSend, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });
      setMessage(response.data);
      setOpenMessage(true);
      setUserInfo(emptyForm);
    } catch (error) {
      console.log("error submitting : ", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "prenom", headerName: "PRENOM", width: 150 },
    { field: "nom", headerName: "NOM", width: 150 },
    { field: "email", headerName: "EMAIL", width: 180 },
    { field: "phone", headerName: "PHONE", width: 150 },
    { field: "userRole", headerName: "ROLE", width: 150 },
    {
      field: "online",
      headerName: "STATUT",
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <RadioButtonCheckedIcon style={{ color: "green" }} />
        ) : (
          "Hors ligne"
        ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            setOpenWithdrawDialog(true);
            setWithdrawalData({ idTarget: params.row.id, idUser: user.id });
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleSearch = async () => {
    setLoading(true);
    try {
      const csrfToken = await fetchXsrfToken();
      const response = await axiosInstance.get(`/account/adr`, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });
      if (response && response.data) {
        setRows(Array.isArray(response.data) ? response.data : [response.data]);
      } else {
        setRows([]);
      }
    } catch (error) {
      setRows([]);
      console.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleDelete = async (id) => {
    const csrfToken = await fetchXsrfToken();
    try {
      await axiosInstance.delete(`/account/adr/${id}`, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });
      setMessage("invite user deleted successfully");
      setOpenMessage(true);
      handleSearch();
    } catch (error) {
      console.error("Error deleting invite");
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <>
      <Container>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="nom"
                label="Nom"
                name="nom"
                autoComplete="nom"
                value={nom}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="prenom"
                label="Prenom"
                name="prenom"
                autoComplete="prenom"
                value={prenom}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                type="email"
                value={email}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                autoComplete="phone"
                type="tel"
                value={phone}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoComplete="new-password"
                type="password"
                value={password}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="new-password"
                type="password"
                value={confirmPassword}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <br />
      <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ width: '100%', height:'400px', margin: '20px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection={false}
          loading={loading}
        />
      </div>
    </div>
      <br />
      <br />
      <Snackbar
        open={openMessage}
        autoHideDuration={4000}
        onClose={handleCloseMessage}
        message={message}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      <WithdrawDialog
        open={openWithdrawDialog}
        message="Enter your password to proceed."
        handleSubmit={handleDelete}
        handleClose={() => setOpenWithdrawDialog(false)}
        withdrawalData={withdrawalData}
      />
    </>
  );
};

export default AdrU;