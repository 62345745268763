import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AuthWrapper } from './auth/AuthWrapper';
import ScrollToTop from './components/structure/ScrollToTop';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <AuthWrapper />
      </BrowserRouter>      
    </div>
  );
}

export default App;