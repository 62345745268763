import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { DataGrid } from "@mui/x-data-grid";
import axiosInstance from "../../auth/axiosInstance";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import DOMPurify from "dompurify";
import { AuthData } from "../../auth/AuthWrapper";
import WithdrawDialog from "../../auth/WithdrawDialog";

const CoursModir = () => {
  const { user } = AuthData();
  const [rows, setRows] = useState([]);
  const [searchType, setSearchType] = useState("id");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isValide, setIsValide] = useState(Array(rows.length).fill(false));
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [openWithdrawDialog2, setOpenWithdrawDialog2] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState(null);
  const statusOptions = [
    { value: "true", label: "Active" },
    { value: "false", label: "En Attente" },
  ];

  const navigate = useNavigate();
  const handleControl = (idCours) => {
    navigate(`/hahid/coursControl/${idCours}`);
  };

  const columns = [
    { field: "idCours", headerName: "ID COURS", width: 130 },
    {
      field: "valide",
      headerName: "STATUS",
      width: 150,
      renderCell: (params) =>
        params.row.valide ? <TaskAltIcon color="success" /> : "En attente",
    },
    {
      field: "valider",
      headerName: "VALIDER",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled={params.row.valide || isValide[params.row.idCours]}
          onClick={() => handleValidate(params.row.idCours)}
        >
          {params.row.valide ? "VALIDÉ" : "VALIDER"}
        </Button>
      ),
    },
    {
      field: "controlLer",
      headerName: "CONTROLLER",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleControl(params.row.idCours)}
        >
          DETAILS
        </Button>
      ),
    },
    {
      field: "retirer",
      headerName: "RETIRER",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          startIcon={<RemoveCircleOutlineIcon />}
          onClick={(e) => {
            e.stopPropagation();
            setOpenWithdrawDialog(true);
            setWithdrawalData({
              idTarget: params.row.idCours,
              idUser: user.id,
            });
          }}
        >
          RETIRER
        </Button>
      ),
    },
    {
      field: "supprimer",
      headerName: "SUPPRIMER",
      width: 180,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            setOpenWithdrawDialog2(true);
            setWithdrawalData({ idUser: user.id , idTarget: params.row.idCours });
          }}
        >
          SUPPRIMER
        </Button>
      ),
    },
    {
      field: "deleteRequest",
      headerName: "DEMANDE SUPPRESSION",
      width: 180,
      renderCell: (params) =>
        params.row.deleteRequest ? (
          <div style={{ display: "flex", marginLeft: "40%" }}>
            <ReportIcon color="error" />
          </div>
        ) : null,
    },
  ];

  const handleDelete = async (idCours) => {
    try {
      const csrfToken = await fetchXsrfToken();
      const res = await axiosInstance.delete(`/cours/${idCours}?id=${user.id}`, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });
      setMessage(res.data);
      setOpenMessage(true);
      setRows([]);
      handleSearch();
    } catch (error) {
      console.error("Error deleting cours");
    }
  };

  const handleRetirer = async (idCours, id) => {
    const csrfToken = await fetchXsrfToken();
    try {
      await axiosInstance.delete(`/cours/ret/${idCours}?idA=${id}`, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });
      setMessage(`Cours NO: ${idCours} est retire `);
      setOpenMessage(true);
      handleSearch();
    } catch (error) {
      console.error("Erreur de retirer le cours");
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const sanitizeSearch = DOMPurify.sanitize(searchValue);
      let response;
      if (searchType === "id") {
        response = await axiosInstance.get(
          `/cours/i?idCours=${sanitizeSearch}`
        );
      } else if (searchType === "emailProf") {
        response = await axiosInstance.get(`/cours/p?email=${sanitizeSearch}`);
      } else if (searchType === "status") {
        response = await axiosInstance.get(
          `/cours/sta?status=${sanitizeSearch}`
        );
      }

      if (response && response.data) {
        setRows(Array.isArray(response.data) ? response.data : [response.data]);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleValidate = async (idCours) => {
    setIsValide((prevState) => ({
      ...prevState,
      [idCours]: true,
    }));
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.put(
        `/cours/val?idCours=${idCours}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setMessage(`Cours NO: ${idCours} est valide `);
      setOpenMessage(true);
    } catch (error) {
      setIsValide((prevState) => ({
        ...prevState,
        [idCours]: false,
      }));
      console.log("ERROR VERIFYING COURS");
    }
  };

  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          gap: "2px",
          marginBottom: "20px",
          marginTop: "50px",
          marginLeft: "5%",
          marginRight: "2px"
        }}
      >
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel id="search-type-label">Filtre</InputLabel>
          <Select
            labelId="search-type-label"
            id="search-type"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            label="Search By"
          >
            <MenuItem value="id">ID COURS</MenuItem>
            <MenuItem value="emailProf">EMAIL PROFESSEUR</MenuItem>
            <MenuItem value="status">STATUT</MenuItem>
          </Select>
        </FormControl>

        {searchType === "status" ? (
        <div>
          <FormControl variant="outlined" style={{ minWidth: 230 }}>
            <InputLabel id="status-label">Statut</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              label="Status"
            >
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleSearch}
            startIcon={<SearchIcon />}
            style={{ margin: "8px" }}
          >
            Chercher
          </Button>
        </div>
      ) : (
        <>
          <TextField
            label="Search Value"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleSearch}
                    startIcon={<SearchIcon />}
                  >
                    Chercher
                  </Button>
                </InputAdornment>
              ),
            }}
            style={{ marginLeft: 16 }}
          />
        </>
      )}
      </div>
      <div style={{ height: 400, width: "85%", marginLeft: "5%" }}>
        <DataGrid
          getRowId={(row) => row.idCours}
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection={true}
          loading={loading}
        />
      </div>
      <Snackbar
        open={openMessage}
        autoHideDuration={4000}
        onClose={handleCloseMessage}
        message={message}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      <WithdrawDialog
        open={openWithdrawDialog}
        message="Veuillez entrer le mot de passe pour continuer."
        handleSubmit={handleRetirer}
        handleClose={() => setOpenWithdrawDialog(false)}
        withdrawalData={withdrawalData}
      />
      <WithdrawDialog
        open={openWithdrawDialog2}
        message="Veuillez entrer le mot de passe pour continuer."
        handleSubmit={handleDelete}
        handleClose={() => setOpenWithdrawDialog2(false)}
        withdrawalData={withdrawalData}
      />
      <br />
    </>
  );
};
export default CoursModir;
