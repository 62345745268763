import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import "../customCss/Register.css";
import axiosInstance from "../../auth/axiosInstance";
import sha256 from "crypto-js/sha256";

export const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [formData, setFormData] = useState({ newPassword: "" });
  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      const hashedPassword = sha256(formData.newPassword).toString();
      await axiosInstance.post(
        `/account/reset?token=${token}`,
        hashedPassword,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );

      setShowSuccess(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("Error resetting password");
      setErrorMessage("Erreur de réinitialisation du mot de passe. Veuillez réessayer plus tard.");
    }
  };

  return (
    <form onSubmit={resetPassword}>
      <section className="vh-100 bg-image">
        <div id="wrap" className="input">
          <header className="input-header">
            <h1>Réinitialiser le mot de passe</h1>
          </header>
          <section className="input-content">
            <div className="input-content-wrap">
              <dl className="inputbox">
                <dt className="inputbox-title">Nouveau MDP</dt>
                <dd className="inputbox-content">
                  <input
                    type="password"
                    id="newPassword"
                    className="form-control form-control-lg input-field"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={(e) =>
                      setFormData({ newPassword: e.target.value })
                    }
                    required
                    minLength={8}
                  />
                  <span className="underline"></span>
                </dd>
              </dl>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                >
                  Réinitialiser
                </button>
              </div>
              <br />
              {errorMessage ? <p className="error">{errorMessage}</p> : null}
              {showSuccess && (
                <p
                  className="success"
                  style={{
                    animation: "fadeIn 0.5s ease-in-out",
                    opacity: 0,
                    animationFillMode: "forwards",
                    fontFamily: "Arial, sans-serif",
                    fontSize: "18px",
                    color: "green",
                  }}
                >
                  Votre mot de passe a été changé avec succès.
                </p>
              )}
            </div>
          </section>
        </div>
      </section>
    </form>
  );
};
