import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import "../customCss/Register.css";
import axiosInstance from "../../auth/axiosInstance";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";

export const Login = () => {
  const navigate = useNavigate();
  const { login } = AuthData();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    emailF: "",
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [openResetDialog, setOpenResetDialog] = useState(false);

  const doLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await login(formData.email, formData.password);
      if (response === true) {
        navigate("/");
      } else if (response === "Incorrect email or password") {
        setErrorMessage("E-mail ou mot de passe incorrect");
      } else if (response === "E-mail non activé") {
        setErrorMessage("Compte non activé");
      } else {
        setErrorMessage("Une erreur est survenue");
      }
    } catch (error) {
      setErrorMessage("Une erreur est survenue");
    }
  };

  const handleOpenResetDialog = () => {
    setOpenResetDialog(true);
  };

  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(
        `/account/forgot-password?email=${formData.emailF}`
      );
      setSnackbarMessage(
        "Un e-mail contenant un lien pour réinitialiser votre mot de passe vous a été envoyé"
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      handleCloseResetDialog();
    } catch (error) {
      setSnackbarMessage(error.response.data);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      handleCloseResetDialog();
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const validate = () => {
    if (!formData.emailF) {
      alert("Veuillez saisir votre adresse e-mail.");
      return false;
    }
    return true;
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (validate()) {
      resetPassword(e);
    }
  };

  return (
    <section className="vh-100 bg-image">
      <div id="wrap" className="input">
        <header className="input-header">
          <h1>Se connecter</h1>
        </header>
        <section className="input-content">
          <div className="input-content-wrap">
            <form onSubmit={doLogin}>
              <dl className="inputbox">
                <dt className="inputbox-title">Email</dt>
                <dd className="inputbox-content">
                  <input
                    type="text"
                    id="email"
                    className="form-control form-control-lg input-field"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    required
                  />
                  <span className="underline"></span>
                </dd>
              </dl>
              <dl className="inputbox">
                <dt className="inputbox-title">Mot de Passe</dt>
                <dd className="inputbox-content">
                  <input
                    type="password"
                    id="password"
                    className="form-control form-control-lg input-field"
                    name="password"
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                    required
                  />
                  <span className="underline"></span>
                </dd>
              </dl>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                >
                  Se Connecter
                </button>
              </div>
              <br />
              {errorMessage ? <p className="error">{errorMessage}</p> : null}
              <p
                className="text-center text-muted mt-5 mb-0"
                onClick={handleOpenResetDialog}
                style={{ cursor: "pointer" }}
              >
                Mot de passe oublié ?
              </p>
              <p className="text-center text-muted mt-5 mb-0">
                Pas encore un membre ?{" "}
                <Link to={"/Register"} className="fw-bold text-body">
                  <u>S'enregistrer</u>
                </Link>
              </p>
            </form>
          </div>
        </section>

        <Dialog open={openResetDialog} onClose={handleCloseResetDialog}>
          <DialogTitle>Mot de passe oublié</DialogTitle>
          <DialogContent>
            <form>
              <TextField
                fullWidth
                label="E-mail de récupération"
                variant="outlined"
                value={formData.emailF}
                onChange={(e) =>
                  setFormData({ ...formData, emailF: e.target.value })
                }
                required
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseResetDialog}>Annuler</Button>
            <Button
              onClick={handleResetPassword}
              variant="contained"
              color="primary"
            >
              Réinitialiser le mot de passe
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </section>
  );
};
