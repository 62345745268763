import  Policy  from "../pages/Policy";
import Account from "../pages/Account";
import AddCours from "../pages/AddCours";
import AdminModir from "../pages/AdminModir";
import Biblio from "../pages/Biblio";
import { Cours } from "../pages/Cours";
import CoursDetails from "../pages/CoursDetails";
import CoursDetailsProf from "../pages/CoursDetailsProf";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import MesCours from "../pages/MesCours";
import Operations from "../pages/Operations";
import Register from "../pages/Register";
import { ResetPassword } from "../pages/ResetPassword";
import CoursModir from "../pages/CoursModir";
import MesCoursDetails from "../pages/MesCoursDetails";
import CoursControl from "../pages/CoursControl";
import AdrU from "../pages/AdrU";
import AdrO from "../pages/AdrO";
import { ConfirmAcc } from "../pages/ConfirmAcc";

export const nav = [
     { path:     "/",                        name: "Accueil",        element: <Home />,
     isMenu: true,     isPrivate: false,    isEleve: false,     isProf: false,  isAdmin: false, isADR: false},

     { path:     "/policy",                  name: "Politique",        element: <Policy />,
     isMenu: false,     isPrivate: false,    isEleve: false,     isProf: false,  isAdmin: false, isADR: false},

     { path:     "/login",                   name: "Login",       element: <Login />,
     isMenu: false,    isPrivate: false,    isEleve: false,     isProf: false,  isAdmin: false, isADR: false},

     { path:     "/register",                name: "Register",    element: <Register />,
     isMenu: false,    isPrivate: false,    isEleve: false,     isProf: false,  isAdmin: false, isADR: false},

     { path:     "/account/confirm/*",           name: "Confirm",    element: <ConfirmAcc />,
          isMenu: false,    isPrivate: false,    isEleve: false,     isProf: false,  isAdmin: false, isADR: false},

     { path:     "/account/reset/*",           name: "Reset",    element: <ResetPassword />,
     isMenu: false,    isPrivate: false,    isEleve: false,     isProf: false,  isAdmin: false, isADR: false},

     { path: "/coursdetails/:idCours",       name: "Cours Details", element: <CoursDetails />,
     isMenu: false,   isPrivate: false,     isEleve: true,      isProf: true,   isAdmin: false, isADR: false},

     { path: "/mescoursdetails/:idCours",   name: "Mes Cours Details", element: <MesCoursDetails />,
     isMenu: false,   isPrivate: true,     isEleve: true,      isProf: false,   isAdmin: false, isADR: false},

     { path:     "/biblio",                  name: "Bibliothèque",      element: <Biblio />,
     isMenu: true,     isPrivate: false,    isEleve: false,     isProf: false,  isAdmin: false, isADR: false},

     { path:     "/mescours",                name: "Mes Cours",    element: <MesCours />,
     isMenu: true,     isPrivate: true,     isEleve: true,      isProf: false,  isAdmin: false, isADR: false},

     { path:     "/operation",                name: "Operations",    element: <Operations />,
     isMenu: true,     isPrivate: true,     isEleve: true,      isProf: false,  isAdmin: false, isADR: false},

     { path:     "/cours",                   name: "Cours",       element: <Cours />,
     isMenu: true,     isPrivate: true,     isEleve: false,     isProf: true,   isAdmin: false, isADR: false},

     { path: "/coursDetailsProf/:idCours",   name: "Mes Cours Details", element: <CoursDetailsProf />,
     isMenu: false,    isPrivate: true,     isEleve: false,     isProf: true,   isAdmin: false, isADR: false},

     { path:     "/addCours",                name: "Ajouter Cours",    element: <AddCours />,
     isMenu: false,    isPrivate: true,     isEleve: false,     isProf: true,   isAdmin: false, isADR: false},

     { path:     "/account",                 name: "Compte",     element: <Account />,
     isMenu: true,     isPrivate: true,     isEleve: true,      isProf: true,   isAdmin: false, isADR: false},

     { path:     "/hahid/utilisateur",            name: "Utilisateur",     element: <AdminModir />,
     isMenu: true,     isPrivate: true,     isEleve: false,      isProf: false,   isAdmin: true, isADR: false},

     { path:     "/hahid/valCours",               name: "Cours",     element: <CoursModir />,
     isMenu: true,     isPrivate: true,     isEleve: false,      isProf: false,   isAdmin: true, isADR: false},

     { path:     "/hahid/coursControl/:idCours",  name: "Cours Control",     element: <CoursControl />,
     isMenu: false,     isPrivate: true,     isEleve: false,      isProf: false,   isAdmin: true, isADR: false},

     { path:     "/adr/u",                    name: "Utilisateur", element: <AdrU />,
          isMenu: true,  isPrivate: true,  isEleve: false,       isProf: false,   isAdmin: false, isADR: true},

     { path:     "/adr/o",                    name: "Operation",   element: <AdrO />,
          isMenu: true,  isPrivate: true,  isEleve: false,       isProf: false,   isAdmin: false, isADR: true},
]