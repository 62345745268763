import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import "../customCss/Register.css";
import axiosInstance from "../../auth/axiosInstance";

export const ConfirmAcc = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [errorMessage, setErrorMessage] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const confirm = async () => {
    try {
      await axiosInstance.get(`/auth/confirm?token=${token}`);
      setShowSuccess(true);
      setTimeout(() => {
        navigate("/");
      }, 4000);
    } catch (error) {
      console.error("Error: ", error);
      setErrorMessage("Veuillez ressayer plus tard ou contactez un admin.");
    }
  };

  return (
    <section
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          backgroundColor: "#fff",
          textAlign: "center",
        }}
      >
        <header style={{ marginBottom: "20px" }}>
          <h1>Confirmation du Compte</h1>
        </header>
        <section>
          {errorMessage ? (
            <p style={{ color: "red", fontWeight: "bold" }}>{errorMessage}</p>
          ) : null}
          {showSuccess && (
            <p
              style={{
                animation: "fadeIn 0.5s ease-in-out",
                opacity: 1,
                transition: "opacity 0.5s ease-in-out",
                fontFamily: "Arial, sans-serif",
                fontSize: "18px",
                color: "green",
              }}
            >
              Votre compte a été activé avec succès.
            </p>
          )}
          {!showSuccess && (
            <button onClick={confirm} className="btn btn-primary">
              Confirmer le compte
            </button>
          )}
        </section>
      </div>
    </section>
  );
};
