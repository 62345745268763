import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../../auth/axiosInstance";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import DOMPurify from "dompurify";
import "../customCss/Account.css";
import WithdrawDialogU from "../../auth/WithdrawDialogU";

const Account = () => {
  const { user } = AuthData();
  const [userData, setUserData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [allFieldsEdited, setAllFieldsEdited] = useState(false);
  let Navigate = useNavigate();

  const sanitizeInputs = (data) => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = DOMPurify.sanitize(data[key]);
      return acc;
    }, {});
  };

  const handleWithdrawDialogOpen = () => {
    setOpenWithdrawDialog(true);
  };

  useEffect(() => {
    const editedFields = Object.values(userData).some((value) => value !== "");
    setAllFieldsEdited(editedFields);
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csrfToken = await fetchXsrfToken();
        const response = await axiosInstance.get(`/account/inf`, {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error");
      }
    };

    fetchData();
  }, [user.name]);

  const handleSave = async () => {
    try {
      const csrfToken = await fetchXsrfToken();
      const userDataJSON = {
        nom: userData.nom,
        prenom: userData.prenom,
        phone: userData.phone,
        compteBk: userData.compteBk,
        ecole: userData.ecole,
        ville: userData.ville,
      };
      const sanitizedData = sanitizeInputs(userDataJSON);

      await axiosInstance.put(
        `/account?email=${user.name}`,
        sanitizedData,
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );

      setEditMode(false);
      Navigate("/");
    } catch (error) {
      console.error("Error" );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        setEditMode(false);
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <section style={{ backgroundColor: "#eee" }}>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol>
            <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
              <MDBBreadcrumbItem>
                <Link to={"/"}> Accueil </Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem>
                <Link to={"/account"}>Profile</Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active> Utilisateur</MDBBreadcrumbItem>
            </MDBBreadcrumb>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <div
                  className="rounded-circle bg-secondary d-inline-flex align-items-center justify-content-center"
                  style={{
                    width: "150px",
                    height: "150px",
                    fontSize: "48px",
                    textTransform: "uppercase",
                    fontFamily: "Arial, sans-serif",
                    color: "#fff",
                  }}
                >
                  <span>{userData.nom && userData.nom.charAt(0)}. </span>
                  <span>{userData.prenom && userData.prenom.charAt(0)}.</span>
                </div>
                <div className="circle-text mint">{userData.userRole}</div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow className="align-items-center">
                  <MDBCol sm="3">
                    <MDBCardText>
                      <strong>Nom</strong>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="8">
                    <MDBCardText className="text-muted">
                      {editMode ? (
                        <input
                          type="text"
                          name="nom"
                          value={userData.nom}
                          onChange={handleChange}
                        />
                      ) : (
                        `${userData.nom}`
                      )}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow className="align-items-center">
                  <MDBCol sm="3">
                    <MDBCardText>
                      <strong>Prénom</strong>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="8">
                    <MDBCardText className="text-muted">
                      {editMode ? (
                        <input
                          type="text"
                          name="prenom"
                          value={userData.prenom}
                          onChange={handleChange}
                        />
                      ) : (
                        `${userData.prenom}`
                      )}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow className="align-items-center">
                  <MDBCol sm="3">
                    <MDBCardText>
                      <strong>E-mail</strong>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="8">
                    <MDBCardText className="text-muted">
                      {userData.email}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow className="align-items-center">
                  <MDBCol sm="3">
                    <MDBCardText>
                      <strong>Téléphone</strong>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="8">
                    <MDBCardText className="text-muted">
                      {editMode ? (
                        <input
                          type="text"
                          name="phone"
                          value={userData.phone}
                          onChange={handleChange}
                        />
                      ) : (
                        `${userData.phone}`
                      )}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow className="align-items-center">
                  <MDBCol sm="3">
                    <MDBCardText>
                      <strong>Banque RIB</strong>
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol sm="8">
                    <MDBCardText className="text-muted">
                      {editMode ? (
                        <input
                          type="text"
                          name="compteBk"
                          value={userData.compteBk}
                          onChange={handleChange}
                        />
                      ) : (
                        `${userData.compteBk}`
                      )}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                {userData.ecole !== null && (
                  <MDBRow className="align-items-center">
                    <MDBCol sm="3">
                      <MDBCardText>
                        <strong>Ecole</strong>
                      </MDBCardText>
                    </MDBCol>
                    <MDBCol sm="8">
                      <MDBCardText className="text-muted">
                        {editMode ? (
                          <input
                            type="text"
                            name="ecole"
                            value={userData.ecole}
                            onChange={handleChange}
                          />
                        ) : (
                          `${userData.ecole}`
                        )}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                )}
                <hr />
                {userData.ville !== null && (
                  <MDBRow className="align-items-center">
                    <MDBCol sm="3">
                      <MDBCardText>
                        <strong>Ville</strong>
                      </MDBCardText>
                    </MDBCol>
                    <MDBCol sm="8">
                      <MDBCardText className="text-muted">
                        {editMode ? (
                          <input
                            type="text"
                            name="ville"
                            value={userData.ville}
                            onChange={handleChange}
                          />
                        ) : (
                          `${userData.ville}`
                        )}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow>
        <MDBCol lg="8" className="offset-lg-4">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBRow className="align-items-center">
                <MDBCol sm="3">
                </MDBCol>
                <MDBCol sm="8">
                  <strong>Pour Modifier Vos Infos </strong>
                </MDBCol>
                <MDBCol sm="1" className="d-flex justify-content-end">
                  {!editMode && (
                    <button className="btn" onClick={() => setEditMode(true)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      {editMode && (
        <MDBRow>
          <MDBCol sm="12" className="text-end mt-4">
            <MDBBtn
              
              disabled={!allFieldsEdited}
              color="primary"
              onClick={handleWithdrawDialogOpen}
            >
              <FontAwesomeIcon icon={faSave} className="me-2" />
              Enregistrer
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      )}

      {openWithdrawDialog && (
        <WithdrawDialogU
          open={openWithdrawDialog}
          message="Veuillez saisir votre mot de passe pour continuer."
          handleSubmit={handleSave}
          handleClose={() => setOpenWithdrawDialog(false)}
          withdrawalData={{ idUser: user.id, idTarget: user.id }}
        />
      )}
      </MDBContainer>
    </section>
  );
};

export default Account;