import axiosInstance from "./axiosInstance";

export const fetchXsrfToken = async () => {
  try {
    const response = await axiosInstance.get("/csrf-token", {
      withCredentials: true,
    });
    const xsrfTokenFromBody = response.data;

    if (xsrfTokenFromBody) {
      return xsrfTokenFromBody;
    } else {
      console.error("CSRF token not found in response body");
      return null;
    }
  } catch (error) {
    console.error("Error fetching CSRF token:", error);
    return null;
  }
};