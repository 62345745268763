import axiosInstance from "./axiosInstance";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import RenderFooter from "../components/structure/Footer";
import {
  RenderMenu,
  RenderRoutes,
} from "../components/structure/RenderNavigation";
import Loading from "../components/structure/Loading";
import { fetchXsrfToken } from "./fetchXsrfToken";
import sha256 from "crypto-js/sha256";
import { toast } from "react-toastify";

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);
export const AuthWrapper = () => {
  const [user, setUser] = useState({ name: "", id: "", role: "", solde: 0 });
  const [loading, setLoading] = useState(false);
  const [lastLoginTime, setLastLoginTime] = useState(new Date());
  const [lastRefreshTokenTime, setLastRefreshTokenTime] = useState(new Date());

  const fetchUserInfo = useCallback(async () => {
      try {
        const csrfToken = await fetchXsrfToken();
        setLoading(true);
        const userRes = await axiosInstance.get(`/account/inf`, {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        });
        setUser({
          name: userRes?.data?.email,
          id: userRes?.data?.id,
          role: userRes?.data?.userRole,
          solde: userRes?.data?.solde,
        });
      } catch (error) {
        console.error("Error");
        setUser({ name: "", id: null, role: "", solde: 0 });
      } finally {
        setLoading(false);
      }
    }, []);

  const refreshAccessToken = useCallback(async () => {
      try {
        const csrfToken = await fetchXsrfToken();
        await axiosInstance.post(
          "/auth/refresh-token",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "X-XSRF-TOKEN": csrfToken,
            },
            withCredentials: true,
          }
        );
        setLastRefreshTokenTime(new Date());
      } catch (error) {
        fetchUserInfo();
        toast.error("Votre session a expiré ! Merci de vous reconnecter!");
        localStorage.removeItem("connected");
        localStorage.removeItem("limit");
      }
    }, [fetchUserInfo]);

    useEffect(() => {
      const connected = localStorage.getItem("connected");
      const limit = localStorage.getItem("limit");
    
      const fetchData = async () => {
        if (connected === "true" && limit !== "true") {
          localStorage.setItem("limit", "true");
          try {
            await refreshAccessToken();
            await fetchUserInfo();
          } catch (error) {
            console.error("Error ");
          }
        }
      };
    
      fetchData();
    
      const handleBeforeUnload = () => {
        localStorage.removeItem("limit");
      };
    
      window.addEventListener("beforeunload", handleBeforeUnload);
    
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [fetchUserInfo, refreshAccessToken]);
    

  useEffect(() => {
    let interval;
    if (user.id) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        if (
          now - lastLoginTime.getTime() > 9 * 60 * 1000 &&
          now - lastRefreshTokenTime.getTime() > 9 * 60 * 1000
        ) {
          refreshAccessToken();
        }
      }, 30000);
    }

    return () => clearInterval(interval);
  }, [lastLoginTime, lastRefreshTokenTime, refreshAccessToken, user.id]);

  const login = async (userName, password) => {
    try {
      const hashedPassword = sha256(password).toString();
      await axiosInstance.post(
        "/auth/login",
        {
          email: userName,
          password: hashedPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      localStorage.setItem("connected", "true");
      await fetchUserInfo();
      setLastLoginTime(new Date());
      setLastRefreshTokenTime(new Date());

      return true;
    } catch (error) {
      console.error("Error during login");
      if(error.response.data ==="E-mail non activé"){
        return "E-mail non activé";
      }
      else if (error.response && error.response.status === 403) {
        return "Incorrect email or password";
      } else {
        return false;
      }
    }
  };

  const logout = async () => {
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.post(
        "/logout",
        {},
        {
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
          withCredentials: true,
        }
      );
      setUser({ name: "", id: null, role: "", solde: 0 });
      localStorage.removeItem("connected");
      localStorage.removeItem("limit");
      setLastLoginTime(null);
      setLastRefreshTokenTime(null);
    } catch (error) {
      console.error("Error during logout:");
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <>
        <RenderMenu />
        {loading ? <Loading /> : <RenderRoutes />}
        <RenderFooter />
      </>
    </AuthContext.Provider>
  );
};