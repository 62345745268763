import { FaCoins, FaUser } from "react-icons/fa";
import { Link, Route, Routes } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import CoursDetails from "../pages/CoursDetails";
import { nav } from "./navigation";
import React, { useState, useEffect } from "react";
import { IconButton, Menu, MenuItem as MuiMenuItem } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import axiosInstance from "../../auth/axiosInstance";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";

export const RenderRoutes = () => {
  const { user } = AuthData();

  return (
    <Routes>
      {nav.map((r, i) => {
        if (r.isPrivate) {
          if (
            user &&
            user.role &&
            ((r.isProf && user.role === "prof") ||
              (r.isEleve && user.role === "eleve") ||
              (r.isADR &&
                user.role !== "prof" &&
                user.role !== "eleve" &&
                user.role !== "adminModir") ||
              (r.isAdmin && user.role === "adminModir"))
          ) {
            return <Route key={i} path={r.path} element={r.element} />;
          }
        } else {
          return <Route key={i} path={r.path} element={r.element} />;
        }
        return null;
      })}
      <Route path="/coursdetails/:courseId" element={<CoursDetails />} />
    </Routes>
  );
};

export const RenderMenu = () => {
  const { user, logout } = AuthData();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    if (user) {
      setDropdownOpen(false);
    }
  }, [user]);

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(".circle-dropdown-content");
      const circle = document.querySelector(".circle");
      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        circle &&
        !circle.contains(event.target)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const csrfToken = await fetchXsrfToken();
        const response = await axiosInstance.get("/notif", {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        });
        setNotifications(response.data);
      } catch (error) {
        console.error("Error");
      }
    };

    const checkNotificationCount = async () => {
      try {
        const countNotifResponse = await axiosInstance.get("/notif/nbr", {
          withCredentials: true,
        });
        const countNotif = countNotifResponse.data;
        setCountNotif(countNotif);

        if (countNotif > 0) {
          fetchNotifications();
        } else {
          setNotifications([
            { body: "Aucune nouvelle notification à afficher" },
          ]);
        }
      } catch (error) {
        console.error("Error");
      }
    };

    if (user) {
      checkNotificationCount();
    }
  }, [user]);

  const [countNotif, setCountNotif] = useState(0);

  const handleMarkAsRead = async () => {
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.post(
        "/notif/read",
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setNotifications([]);
      setNotifications([{ body: "Aucune nouvelle notification à afficher" }]);
      setCountNotif(0);
      handleMenuClose();
    } catch (error) {
      console.error("Error");
    }
  };

  const MenuItem = ({ r }) => (
    <div className="menuItem">
      <Link to={r.path}>{r.name} </Link>
    </div>
  );

  const MenuItemCircle = ({ r }) => (
    <div className="circle-dropdown-item">
      <Link to={r.path} style={{ color: "black", textDecoration: "none" }}>
        {r.name}
      </Link>
    </div>
  );

  return (
    <div className="menu">
      <div className="menuItem">
        <Link to="/">
          <img src="https://eswaye.com/Elogo.png" alt="Logo" className="logo" />
        </Link>
      </div>

      {nav.map((r, i) =>
        r.isMenu && !r.isPrivate ? <MenuItem key={i} r={r} /> : null
      )}
      {user && user.role ? (
        <div className="menuItemCustom">
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleMenuOpen}>
              <Badge badgeContent={countNotif} color="error" overlap="circular">
                <NotificationsIcon
                  style={{ color: "white", fontSize: "24px" }}
                />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              {notifications.length > 0 ? (
                notifications
                  .filter((notification) => !notification.opened)
                  .map((notification) => (
                    <MuiMenuItem
                      key={notification.id}
                      onClick={handleMenuClose}
                    >
                      {notification.body}
                    </MuiMenuItem>
                  ))
              ) : (
                <MuiMenuItem onClick={handleMenuClose}>
                  Aucune nouvelle notification à afficher
                </MuiMenuItem>
              )}
              {notifications.length > 0 && (
                <MuiMenuItem
                  onClick={handleMarkAsRead}
                  style={{
                    justifyContent: "center",
                    borderTop: "1px solid white",
                  }}
                >
                  Marquer comme lu
                </MuiMenuItem>
              )}
            </Menu>
          </div>
          <div className="circle-dropdown">
            <div className="circle" onClick={toggleDropdown}>
              {user.name.slice(0, 2).toUpperCase()}
            </div>
            {dropdownOpen && (
              <div className="circle-dropdown-content">
                {nav.map((r, i) =>
                  r.isPrivate &&
                  r.isMenu &&
                  user.role &&
                  ((r.isProf && user.role === "prof") ||
                    (r.isEleve && user.role === "eleve") ||
                    (r.isADR &&
                      user.role !== "prof" &&
                      user.role !== "eleve" &&
                      user.role !== "adminModir") ||
                    (r.isAdmin && user.role === "adminModir")) ? (
                    <MenuItemCircle key={i} r={r} />
                  ) : null
                )}
                <div className="circle-dropdown-item">
                  <Link
                    to="/"
                    onClick={logout}
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Se déconnecter
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="balance">
            <span
              style={{
                fontWeight: "bold",
                color: "green",
                marginRight: "4px",
              }}
            >
              Solde:
            </span>
            <span style={{ fontSize: "13px", fontFamily: "Arial, sans-serif" }}>
              {user.solde}
            </span>
            &nbsp;
            <FaCoins style={{ marginTop: "2px" }} />
          </div>
        </div>
      ) : (
        <>
          <div className="dropdown">
            <button className="dropbtn">Compte</button>
            <div className="dropdown-content">
              <Link to="Login">Se connecter</Link>
              <Link to="Register">Créer un compte</Link>
            </div>
          </div>
          <FaUser className="userIcon" />
        </>
      )}
    </div>
  );
};