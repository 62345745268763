import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axiosInstance from './axiosInstance';
import { fetchXsrfToken } from './fetchXsrfToken';
import sha256 from 'crypto-js/sha256';

const WithdrawDialogU = ({ open, message, handleSubmit, handleClose, withdrawalData }) => {
  const [password, setPassword] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const csrfToken = await fetchXsrfToken();
    try {
        const hashedPassword = sha256(password).toString();
        const response = await axiosInstance.post(
        "/allow/user", {
          idUser: parseInt(withdrawalData.idUser),
          idTarget: parseInt(withdrawalData.idTarget),
          password: hashedPassword,
        },
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      if (response.data === true) {
        if(withdrawalData.email){
          handleSubmit(withdrawalData.idTarget, withdrawalData.email);
        }else{
          handleSubmit(withdrawalData.idTarget, withdrawalData.idUser);
        }
      } else {
        console.error("Error");
      }
    } catch (error) {
      console.error("Error");
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleFormSubmit}>
        <DialogContent>
          <p>{message}</p>
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">Confirmer</Button>
          <Button onClick={handleClose} color="secondary">Annuler</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default WithdrawDialogU;