import axiosInstance from "../../auth/axiosInstance";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { AuthData } from "../../auth/AuthWrapper";

export const Cours = ({ profId }) => {
  const [cours, setCours] = useState([]);
  const { user } = AuthData();

  const loadCours = useCallback(async () => {
    try {
      const result = await axiosInstance.get(`/cours/p?email=${user.name}`);
      setCours(result.data);
    } catch (error) {
      console.error("Error ");
    }
  }, [user.name]);

  useEffect(() => {
    loadCours();
  }, [loadCours, user.id]);

  const parseLocalDateTime = (dateTimeArray) => {
    const [year, month, day, hour, minute] = dateTimeArray;
    return new Date(year, month - 1, day, hour, minute);
  };

  return (
    <>
      <div className="container">
        <div className="py-4">
          <Link
            to="/addCours"
            className="btn btn-primary"
            style={{
              marginRight: "auto",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            Ajouter un Cours
          </Link>
          <TableContainer component={Paper} style={{ marginBottom: "37%" }}>
            <Table aria-label="cours table">
              <TableHead>
                <TableRow>
                  <TableCell>NO</TableCell>
                  <TableCell>Titre</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Prix</TableCell>
                  <TableCell>Première Séance</TableCell>
                  <TableCell>Deuxième Séance</TableCell>
                  <TableCell>Troisième Séance</TableCell>
                  <TableCell>Salle de Cours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cours.map((cours) => (
                  <TableRow key={cours.idCours}>
                    <TableCell component="th" scope="row">
                      {cours.idCours}
                    </TableCell>
                    <TableCell>{cours.title}</TableCell>
                    <TableCell>{cours.description}</TableCell>
                    <TableCell>{cours.price}</TableCell>
                    <TableCell>
                      {parseLocalDateTime(cours.date_d).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {cours.date_s
                        ? parseLocalDateTime(cours.date_s).toLocaleString()
                        : ""}
                    </TableCell>
                    <TableCell>
                      {cours.date_t
                        ? parseLocalDateTime(cours.date_t).toLocaleString()
                        : ""}
                    </TableCell>
                    <TableCell>
                      <Link to={`/coursDetailsProf/idCours=${cours.idCours}`}>
                        <img
                          src="/img/start.png"
                          alt="Salle"
                          style={{
                            width: "60px",
                            height: "30px",
                            borderRadius: "15px",
                            marginLeft: "20px",
                          }}
                        />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};