import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "../customCss/Slideshow.css";

const fadeImages = [
  {
    url: "/img/Slider1.jpg",
    caption: "",
    alt: 'image',
  },
  {
    url: "/img/Slider2.jpg",
    caption: "",
    alt: 'image',
  },
];

const Slideshow = () => {
  const fadeProperties = {
    duration: 5000,
    transitionDuration: 1000,
    infinite: true,
    indicators: true,
    arrows: false,
  };

  return (
    <div className="slide-container">
      <Fade {...fadeProperties}>
        {fadeImages.map((fadeImage, index) => (
          <div className="each-fade" key={index}>
            <img
              className="fade-image"
              src={fadeImage.url}
              alt={fadeImage.alt}
            />
            <h2>{fadeImage.caption}</h2>
          </div>
        ))}
      </Fade>
    </div>
  );
};

export default Slideshow;