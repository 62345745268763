import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axiosInstance from "../../auth/axiosInstance";
import React, { useState } from "react";
import { AuthData } from "../../auth/AuthWrapper";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import WithdrawDialog from "../../auth/WithdrawDialog";

const AdminModir = () => {
  const { user } = AuthData();
  const [rows, setRows] = useState([]);
  const [searchType, setSearchType] = useState("id");
  const [searchValue, setSearchValue] = useState("");
  const [idMin, setIdMin] = useState("");
  const [idMax, setIdMax] = useState("");
  const [loading, setLoading] = useState(false);
  const [soldeAmounts, setSoldeAmounts] = useState({});
  const [motifList, setMotifList] = useState({});
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [motifInputFocused, setMotifInputFocused] = useState(false);
  const [isVerifying, setIsVerifying] = useState(
    Array(rows.length).fill(false)
  );
  const [isLocked, setIsLocked] = useState(Array(rows.length).fill(false));
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [openWithdrawDialog2, setOpenWithdrawDialog2] = useState(false);
  const [openWithdrawDialog3, setOpenWithdrawDialog3] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "prenom", headerName: "PRENOM", width: 100 },
    { field: "nom", headerName: "NOM", width: 100 },
    { field: "email", headerName: "EMAIL", width: 150 },
    { field: "phone", headerName: "PHONE", width: 100 },
    { field: "userRole", headerName: "ROLE", width: 70 },
    {
      field: "solde",
      headerName: "SOLDE",
      width: 90,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>{params.row.solde}</div>
        </div>
      ),
    },
    {
      field: "online",
      headerName: "LOGGED AT",
      width: 120,
      renderCell: (params) => {
        const onlineDate = params.value ? new Date(params.value) : null;
        if (onlineDate instanceof Date && !isNaN(onlineDate)) {
          const formattedDate = onlineDate.toLocaleString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });
          return formattedDate;
        } else {
          return "Hors ligne";
        }
      },
    },
    {
      field: "operation",
      headerName: "OPERATION",
      width: 400,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Button
            variant="contained"
            style={{ minWidth: "25px", padding: "4px 6px" }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenWithdrawDialog(true);
              setWithdrawalData({ idTarget: params.row.id , idUser: user.id });
            }}
          >
            -
          </Button>
          <TextField
            value={soldeAmounts[params.row.id] || ""}
            onChange={(e) => handleSoldeChange(params.row.id, e.target.value)}
            variant="outlined"
            style={{ width: "60px" }}
            placeholder="MAD"
          />
          <TextField
            value={motifList[params.row.id] || ""}
            onChange={(e) => handleMotifChange(params.row.id, e.target.value)}
            onFocus={handleMotifInputFocus}
            onBlur={handleMotifInputBlur}
            onKeyDown={handleKeyDown}
            placeholder="Motif"
            variant="outlined"
            style={{ width: "200px", flexGrow: 1 }}
            inputProps={{ style: { fontFamily: "inherit" } }}
          />
          <Button
            variant="contained"
            style={{ minWidth: "25px", padding: "4px 6px" }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenWithdrawDialog2(true);
              setWithdrawalData({ idTarget: params.row.id , idUser: user.id});
            }}
          >
            +
          </Button>
        </div>
      ),
    },
    {
      field: "verified",
      headerName: "VÉRIFIER",
      width: 110,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled={params.row.verified || isVerifying[params.row.id]}
          onClick={() => handleVerify(params.row.id)}
        >
          {params.row.verified ? "VÉRIFIÉ" : "VÉRIFIER"}
        </Button>
      ),
    },
    {
      field: "verouiller",
      headerName: "STATUT",
      width: 130,
      renderCell: (params) => (
        <Button
          variant="contained"
          color={
            params.row.locked || isLocked[params.row.id] ? "success" : "primary"
          }
          onClick={() => toggleLock(params.row.id)}
        >
          {params.row.locked || isLocked[params.row.id]
            ? "DÉVERROUILLER"
            : "VÉROUILLER"}
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      width: 130,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            setOpenWithdrawDialog3(true);
            setWithdrawalData({ idTarget: params.row.id , idUser: user.id });
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleDelete = async (id) => {
    const csrfToken = await fetchXsrfToken();
    axiosInstance
      .delete(`/account/mhi/${id}`, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      })
      .then(() => {
        console.log(`Successfully deleted user`);
        handleSearch();
      })
      .catch((error) => {
        console.error("Error deleting user");
      });
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      let response;
      const csrfToken = await fetchXsrfToken();
      if (searchType === "id") {
        response = await axiosInstance.get(`/account?id=${searchValue}`, {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        });
      } else if (searchType === "email") {
        response = await axiosInstance.get(`/account/e?email=${searchValue}`, {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        });
      } else if (searchType === "range") {
        response = await axiosInstance.get(
          `/account/r?idMin=${idMin}&idMax=${idMax}`,
          {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": csrfToken,
            },
          }
        );
      }

      if (response && response.data) {
        setRows(Array.isArray(response.data) ? response.data : [response.data]);
      } else {
        setRows([]);
      }
    } catch (error) {
      setRows([]);
      console.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleMotifInputFocus = () => {
    setMotifInputFocused(true);
  };

  const handleMotifInputBlur = () => {
    setMotifInputFocused(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && motifInputFocused) {
      e.stopPropagation();
    }
  };

  const handleSoldeChange = (id, amount) => {
    setSoldeAmounts((prevState) => ({
      ...prevState,
      [id]: amount,
    }));
  };

  const handleMotifChange = (id, motif) => {
    setMotifList((prevState) => ({
      ...prevState,
      [id]: motif,
    }));
  };

  const handleMinusClick = async (id) => {
    try {
      const csrfToken = await fetchXsrfToken();
      const montant = soldeAmounts[id];
      const motif = motifList[id];
      await axiosInstance.post(
        `/account/p?id=${id}&montant=${montant}&Op=${user.id}`,
        motif,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "text/plain",
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      handleSearch();
      setMessage("Montant paye du solde de l'utilisateur ");
      setOpenMessage(true);
      setSoldeAmounts((prevState) => ({
        ...prevState,
        [id]: "",
      }));
      setMotifList((prevState) => ({
        ...prevState,
        [id]: "",
      }));
    } catch (error) {
      console.log("error buying solde ");
    }
  };

  const handlePlusClick = async (id) => {
    try {
      const csrfToken = await fetchXsrfToken();
      const montant = soldeAmounts[id];
      const motif = motifList[id];
      await axiosInstance.post(
        `/account/b?id=${id}&montant=${montant}&Op=${user.id}`,
        motif,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "text/plain",
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      handleSearch();
      setMessage("montant ajoute au solde de l'utilisateur ");
      setOpenMessage(true);
      setSoldeAmounts((prevState) => ({
        ...prevState,
        [id]: "",
      }));
      setMotifList((prevState) => ({
        ...prevState,
        [id]: "",
      }));
    } catch (error) {
      console.log("error paying amount ");
    }
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const handleVerify = async (userId) => {
    setIsVerifying((prevState) => ({
      ...prevState,
      [userId]: true,
    }));
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.post(
        `/account/v?id=${userId}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      handleSearch();
    } catch (error) {
      setIsVerifying((prevState) => ({
        ...prevState,
        [userId]: false,
      }));
      console.log("ERROR VERIFYING USER");
    }
  };

  const handleLock = async (userId) => {
    const isCurrentlyLocked = isLocked[userId];
    const newLockState = !isCurrentlyLocked;
    setIsLocked((prevState) => ({
      ...prevState,
      [userId]: newLockState,
    }));
    try {
      const csrfToken = await fetchXsrfToken();
      if (newLockState) {
        await axiosInstance.post(
          `/account/l?id=${userId}`,
          {},
          {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": csrfToken,
            },
          }
        );
      } else {
        await axiosInstance.post(
          `/account/u?id=${userId}`,
          {},
          {
            withCredentials: true,
            headers: {
              "X-XSRF-TOKEN": csrfToken,
            },
          }
        );
      }
      handleSearch();
    } catch (error) {
      setIsLocked((prevState) => ({
        ...prevState,
        [userId]: isCurrentlyLocked,
      }));
      console.log("ERROR TOGGLING LOCK FOR USER");
    }
  };

  const toggleLock = (userId) => {
    handleLock(userId);
  };

  return (
    <>
      <br />
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "20px",
          marginTop: "50px",
          marginLeft: "120px",
        }}
      >
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel id="search-type-label">Search By</InputLabel>
          <Select
            labelId="search-type-label"
            id="search-type"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            label="Search By"
          >
            <MenuItem value="id">ID</MenuItem>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="range">Range</MenuItem>
          </Select>
        </FormControl>
        {searchType === "range" ? (
          <>
            <TextField
              label="Min ID"
              variant="outlined"
              value={idMin}
              onChange={(e) => setIdMin(e.target.value)}
              placeholder="Enter Min ID"
            />
            <TextField
              label="Max ID"
              variant="outlined"
              value={idMax}
              onChange={(e) => setIdMax(e.target.value)}
              placeholder="Enter Max ID"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      onClick={handleSearch}
                      startIcon={<SearchIcon />}
                      disabled={!idMax || !idMin}
                    >
                      Search
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : (
          <TextField
            label="Search Value"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleSearch}
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>
      <div style={{ height: 400, width: "95%", marginLeft: "30px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection={false}
          loading={loading}
        />
      </div>
      <Snackbar
        open={openMessage}
        autoHideDuration={4000}
        onClose={handleCloseMessage}
        message={message}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      <WithdrawDialog
        open={openWithdrawDialog}
        message="Veuillez entrer le mot de passe pour continuer."
        handleSubmit={handleMinusClick}
        handleClose={() => setOpenWithdrawDialog(false)}
        withdrawalData={withdrawalData}
      />
      <WithdrawDialog
        open={openWithdrawDialog2}
        message="Veuillez entrer le mot de passe pour continuer."
        handleSubmit={handlePlusClick}
        handleClose={() => setOpenWithdrawDialog2(false)}
        withdrawalData={withdrawalData}
      />
      <WithdrawDialog
        open={openWithdrawDialog3}
        message="Veuillez entrer le mot de passe pour continuer."
        handleSubmit={handleDelete}
        handleClose={() => setOpenWithdrawDialog3(false)}
        withdrawalData={withdrawalData}
      />
      <br />
      <br />
    </>
  );
};

export default AdminModir;