import React, { useState } from "react";
import { Typography, Paper, IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Policy = () => {
  const [openInscription, setOpenInscription] = useState(false);
  const [openDeroulement, setOpenDeroulement] = useState(false);
  const [openRemboursement, setOpenRemboursement] = useState(false);
  const [openSuppression, setOpenSuppression] = useState(false);

  const handleToggleInscription = () => {
    setOpenInscription(!openInscription);
  };

  const handleToggleDeroulement = () => {
    setOpenDeroulement(!openDeroulement);
  };

  const handleToggleRemboursement = () => {
    setOpenRemboursement(!openRemboursement);
  };

  const handleToggleSuppression = () => {
    setOpenSuppression(!openSuppression);
  };

  return (
    <>
      <div style={{ padding: "5%", fontSize: "16px" }}>
        <p>
          <strong className="policy-title">
            <u>REGLES ET POLITIQUE DE E-SWAYE</u>
          </strong>
        </p>
        <br />
        <p>
          Sur notre site web, la confidentialité de nos visiteurs est l'une de
          nos principales priorités. Ce document de politique de confidentialité
          contient les types d'informations collectées et enregistrées par notre
          site web et comment nous les utilisons.
        </p>
        <br />
        <p>
          Si vous avez des questions supplémentaires ou si vous avez besoin de
          plus d'informations sur notre politique de confidentialité, n'hésitez
          pas à nous contacter.
        </p>

        <br />
        <p>
          <strong
            style={{
              color: "green",
              fontFamily: "Georgia, serif",
              fontSize: "18px",
            }}
          >
            <u>Règles générales</u>
          </strong>
        </p>
        <br />
        <h5>Engagement envers la Protection des Données Personnelles</h5>
        <br />
        <p>
          Nous prenons la protection de vos données personnelles très au
          sérieux. Conformément aux lois et règlements en vigueur, notamment le
          Règlement Général sur la Protection des Données (RGPD), de l'Union
          Européenne, Commission Nationale de Contrôle de la Protection des
          Données (CNDP)... Nous nous engageons à garantir la sécurité et la
          confidentialité de vos informations personnelles. Nous collectons et
          traitons vos données uniquement dans le cadre des finalités légitimes
          et spécifiques pour lesquelles elles ont été fournies. En aucun cas,
          nous ne conservons de documents personnels dans nos bases de données.
        </p>
        <br />
        <br />
        <h5>Collecte et Utilisation des Données</h5>
        <br />
        <p>
          Toutes les données personnelles collectées sur notre site sont
          traitées conformément aux lois applicables. Nous veillons à ce que les
          informations soient recueillies de manière transparente et équitable,
          et qu'elles soient utilisées exclusivement aux fins pour lesquelles
          elles ont été fournies. Nous nous engageons à minimiser la collecte de
          données à ce qui est strictement nécessaire et à obtenir votre
          consentement explicite pour toute utilisation supplémentaire.
        </p>
        <br />
        <h5>Droits des Utilisateurs</h5>
        <br />
        <p>
          Vous avez le droit d'accéder, de rectifier, de supprimer ou de limiter
          le traitement de vos données personnelles. Vous pouvez également vous
          opposer au traitement de vos données ou demander leur portabilité.
          Pour exercer ces droits, vous pouvez nous contacter à l'adresse
          indiquée dans la section "Contact" de notre site. Nous nous engageons
          à répondre à vos demandes dans les meilleurs délais, conformément aux
          exigences légales.
        </p>
        <br />
        <h5>Sécurité des Données</h5>
        <br />
        <p>
          Nous mettons en œuvre des mesures techniques et organisationnelles
          appropriées pour protéger vos données personnelles contre tout accès
          non autorisé, divulgation, altération ou destruction. Nous utilisons
          des protocoles de sécurité robustes et surveillons régulièrement notre
          système pour détecter et prévenir toute vulnérabilité.
        </p>
        <br />
        <p>
          Tout document que vous choisissez de nous envoyer reste sécurisé. Pour
          les documents confidentiels nécessaires à des vérifications, une fois
          la vérification effectuée, ils sont immédiatement supprimés pour
          garantir une protection approfondie de vos données.Nous ne stockons
          jamais d'informations, de fichiers ou de documents à caractère
          personnel dans nos bases de données, quelles que soient les conditions
          ou les objectifs.
          <br />
          <strong>
            {" "}
            Il est strictement interdit de demander ou d'échanger des
            informations personnelles telles que des numéros de téléphone ou
            d'adresse e-mail pendant une séance. Tout manquement à cette règle
            est inacceptable et entraîne la suppression définitive de votre
            compte, y compris de tous vos cours et de votre solde, sans
            possibilité de remboursement ou de contestation. Toute communication
            en dehors du cadre du cours enseigné doit obligatoirement passer par
            l'administration.
          </strong>
        </p>
        <br />
        <h5>Mises à Jour de la Politique de Confidentialité</h5>
        <br />
        <p>
          Nous pouvons occasionnellement mettre à jour cette politique de
          confidentialité pour refléter les modifications de nos pratiques ou
          des lois applicables. Nous vous encourageons à consulter régulièrement
          cette page pour rester informé de nos pratiques de protection des
          données, nos services et nos prix. En cas de modifications
          significatives, nous vous en informerons par un avis bien visible sur
          notre site.
        </p>
        <br />
        <p>
          <strong
            style={{
              color: "green",
              fontFamily: "Georgia, serif",
              fontSize: "18px",
            }}
          >
            <u>Politique de Remboursement</u>
          </strong>
        </p>
        <br />
        <p>
          Si un enseignant annule ou s'absente à plus de deux séances, vous avez
          le droit de demander un remboursement. Ce remboursement prendra la
          forme d'un solde ajouté à votre compte E-SWAYE. Toutefois, si vous
          n'avez pas encore payé et que vous avez seulement utilisé la séance
          gratuite, vous ne serez pas éligible à un remboursement.
        </p>

        <p>
          <strong
            style={{
              color: "green",
              fontFamily: "Georgia, serif",
              fontSize: "18px",
            }}
          >
            <u>Politique de Paiement</u>
          </strong>
        </p>
        <br />
        <p>
          <strong>Pour professeur</strong>
        </p>
        <br />
        <p>
          Vous recevrez votre paiement à la fin de chaque mois, calculé en
          fonction du nombre d'inscrits mentionnés dans les détails de chaque
          cours. Vous devez consentir à ce que la liste des inscrits soit
          disponible pendant le cours. Le montant total de tous vos cours
          dispensés sera versé directement sur le compte bancaire spécifié dans
          votre compte (veuillez vérifier votre RIB). Un récapitulatif de vos
          cours sera envoyé par téléphone, accompagné d'une capture d'écran
          attestant du virement effectué sur votre compte.
        </p>
        <br />
        <p>
          Si vous possédez un compte standard et que votre classe comprend moins
          de dix élèves, Vous recevrez une rémunération équivalente à
          quatre-vingt pour cent (80%) des revenus générés par le cours. En
          revanche, si votre classe compte plus de dix élèves, cette
          rémunération sera de quatre-vingt-cinq pour cent (85%). Toutefois, si
          votre classe compte plus de vingt cinq élèves, ou si vous disposez
          d'un compte Platinum, vous pouvez profiter d'une rémunération de
          quatre-vingt-dix (90%).
        </p>
        <br />
        <p>
          <strong>Pour élève</strong>
        </p>
        <br />
        <p>
          Avant de procéder au virement, veuillez vérifier attentivement le
          numéro de compte bancaire (RIB) mentionné dans le cours auquel vous
          souhaitez vous inscrire. Le cours ne sera débloqué qu'après validation
          de votre virement par l'administration. Si vous utilisez un compte
          bancaire d'une autre banque que celle mentionnée dans le cours,
          veuillez noter qu'un délai de 48 heures peut être nécessaire pour que
          l'administration puisse vérifier le virement. Il est recommandé
          d'utiliser la même banque pour éviter tout retard. Après avoir
          effectué le paiement, veuillez inclure une capture d'écran du virement
          effectué, accompagnée de votre adresse e-mail utilisée dans E-SWAYE,
          du numéro de matière souhaité, et l'envoyer par téléphone (WhatsApp)
          au numéro de l'administrateur auquel vous avez effectué le paiement.
          Assurez-vous de noter la date, le nom et le numéro de l'administrateur
          le jour où vous effectuez le paiement, car les administrateurs peuvent
          changer.
        </p>
        <p>
          <strong>Consentement</strong>
        </p>
        <p>
          En utilisant notre site web, vous consentez par la présente à notre
          politique de confidentialité et acceptez ses termes.
        </p>
      </div>
      <div style={{ paddingLeft: "40%", fontSize: "20px" }}>
        <p>
          <strong>
            <u>F.A.Q.</u>
          </strong>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10%",
        }}
      >
        <Paper
          elevation={3}
          style={{
            width: "85%",
            margin: "15px 0",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Comment se déroule l'inscription ?
          </Typography>
          <IconButton onClick={handleToggleInscription}>
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={openInscription}>
            &nbsp; Après avoir choisi un cours de la bibliothèque, vous avez la
            possibilité d'assister à une séance gratuite pour évaluer la qualité
            de l'enseignant et du cours. Une fois cette séance gratuite
            terminée, vous ne pouvez plus suivre le cours jusqu'au paiement.
            Sinon, vous pouvez choisir de payer dès le départ. Le paiement peut
            s'effectuer de deux manières :
            <ul style={{ marginLeft: "20px", textAlign: "left" }}>
              <li>Directement payer le cours choisi.</li>
              <li>Acheter un solde et payer le cours avec.</li>
            </ul>
          </Collapse>
        </Paper>

        <Paper
          elevation={3}
          style={{
            width: "85%",
            margin: "15px 0",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Comment se déroule la séance ?
          </Typography>
          <IconButton onClick={handleToggleDeroulement}>
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={openDeroulement}>
            &nbsp; En tant qu'élève, vous êtes tenu de respecter les règles de
            conduite dans chaque cours et de traiter les enseignants ainsi que
            les autres élèves avec respect. Après chaque séance, vous avez la
            possibilité de la noter.
          </Collapse>
        </Paper>

        <Paper
          elevation={3}
          style={{
            width: "85%",
            margin: "15px 0",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Quelle est la procédure de remboursement en cas d'annulation ?
          </Typography>
          <IconButton onClick={handleToggleRemboursement}>
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={openRemboursement}>
            &nbsp; Vous serez remboursé sous forme de crédit sur votre compte,
            vous permettant d'accéder à tout cours de votre choix, à condition
            que votre solde total soit suffisant pour couvrir le coût de ce
            cours.
          </Collapse>
        </Paper>

        <Paper
          elevation={3}
          style={{
            width: "85%",
            margin: "15px 0",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Comment supprimer mon compte ?
          </Typography>
          <IconButton onClick={handleToggleSuppression}>
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={openSuppression}>
            &nbsp; Pour éviter tout abus d'inscription et de désinscription, la
            suppression de votre compte est effectuée soit en soumettant une
            demande de suppression, soit en contactant un administrateur par
            e-mail de support ou par téléphone.
          </Collapse>
        </Paper>
      </div>
      <br />
    </>
  );
};

export default Policy;