import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axiosInstance from "../../auth/axiosInstance";
import React, { useState } from "react";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";

const AdrO = () => {
  const [rows, setRows] = useState([]);
  const [searchType, setSearchType] = useState("date");
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = async () => {
    try {
      let response;
      if (searchType === "date") {
        const date = searchValue;
        const csrfToken = await fetchXsrfToken();
        response = await axiosInstance.get(`/history/adr?date=${date}`, {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        });
      }
      if (response && response.data) {
        setRows(response.data);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.error("Error fetching rows");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "20px",
          marginTop: "50px",
          marginLeft: "12%",
        }}
      >
        <FormControl variant="outlined" style={{ minWidth: 180 }}>
          <InputLabel id="search-type-label">Filtre</InputLabel>
          <Select
            labelId="search-type-label"
            id="search-type"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            label="Search By"
          >
            <MenuItem value="date">Date</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Date"
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  onClick={handleSearch}
                  startIcon={<SearchIcon />}
                >
                  Chercher
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div>
        {rows.length === 0 ? (
          <div className="not-found-message">
            <p style={{ marginBottom: "15%" }}>
              Aucune Operation n'a été trouvée.
            </p>
          </div>
        ) : (
          <TableContainer component={Paper} sx={{ width: "85%", margin: "5%" }}>
            <Table sx={{ minWidth: 650 }} aria-label="spanning table">
              <caption
                style={{
                  padding: "10px",
                  fontSize: "1.25rem",
                  textAlign: "center",
                }}
              >
                Operation Details
              </caption>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
                  >
                    Details
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", width: 90 }}
                  >
                    ID Op
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", width: 130 }}
                  >
                    Date Operation
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", width: 90 }}
                  >
                    ID Admin
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", width: 130 }}
                  >
                    Email User
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", width: 200 }}
                  >
                    Motif
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", width: 90 }}
                  >
                    Operation
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", width: 120 }}
                  >
                    Montant
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.idOp}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell align="right">{row.idOp}</TableCell>
                    <TableCell align="right">
                      {new Date(row.dateOp).toLocaleString()}
                    </TableCell>
                    <TableCell align="right">{row.idAdmin}</TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.motif}</TableCell>
                    <TableCell align="right">{row.operation}</TableCell>
                    <TableCell align="right">{row.montant}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default AdrO;